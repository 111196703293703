'use client';

import { Layout, ProductsHighlight } from '@common';
import { useGTMDataLayer } from '@hooks';
import { useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';

const Custom404 = () => {
  const { pushToDataLayer } = useGTMDataLayer({ runOnce: true });
  useEffect(() => {
    pushToDataLayer('notFoundPage', {});
  }, [pushToDataLayer]);

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center py-16">
        <div className="max-w-[420px] text-center object-contain">
          <div className="mb-5">
            <Image
              style={{ width: '100%' }}
              className="w-full"
              width={0}
              height={0}
              sizes="100vw"
              alt="Not found image"
              src="/assets/icons/404.png"
            />
          </div>
          <span className="font-bold text-primary-medium text-lg">
            Ops! Página não encontrada.
          </span>
          <Link
            className="inline-block text-sm mt-10 border-2 rounded border-primary-medium text-primary-medium hover:border-primary-dark hover:text-primary-dark p-4 px-6 font-bold"
            href="/"
          >
            VOLTAR PARA A PÁGINA INICIAL
          </Link>
        </div>
      </div>
      <ProductsHighlight />
    </Layout>
  );
};

export default Custom404;
